import React from "react"
import { Link } from "gatsby"
import { useUrlSearchParams } from "use-url-search-params";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import landlord_header from "../images/landlord_header.png"

import wave from "../images/wave.svg"

const PartnerSuccess = () => {

  const [params] = useUrlSearchParams({});

  return (
    <Layout>
      <SEO
        title="Partner Success"
        description="Partner Success"
        image={landlord_header}
      />

      <section className="container mx-auto mt-32 p-10">
        <div className="w-full text-center">
          <h2 className="text-2xl md:text-5xl font-bold text-gray-700 leading-none">
            Great! 
          </h2>
          <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          You'll be contacted ASAP about your enquiry for {params.partner}.
        </p>

        <br />
        <a to="/partners" target="_blank" className="inline-block btn-primary text-center mt-10">
        Back to Partners
        </a>
        </div>
      </section>

      <img classNameName="w-full" src={wave} alt="" />

      <Footer />
    </Layout>
  )
}

export default PartnerSuccess
